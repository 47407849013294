<template>
	<svg version="1.1" id="CallSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		 viewBox="0 0 14 14" style="enable-background:new 0 0 14 14;" xml:space="preserve">
		<linearGradient id="CallSvg_1" gradientUnits="userSpaceOnUse" x1="0.1667" y1="7" x2="13.8333" y2="7">
			<stop  offset="0" style="stop-color:#CCCCCC"/>
			<stop  offset="1" style="stop-color:#0078C1"/>
		</linearGradient>
		<path class="st0" d="M1,2.2c0.2-0.3,1.4-1.6,2.2-1.6c0.2,0,0.5,0.2,0.6,0.3h0c0.4,0.4,1.6,1.9,1.6,2.2C5.6,4,4.7,4.5,5,5.3C5.7,7,7,8.3,8.7,9c0.8,0.3,1.2-0.6,2-0.5c0.3,0.1,1.8,1.2,2.2,1.6l0,0c0.2,0.2,0.3,0.4,0.3,0.6c0,0.9-1.3,2.1-1.6,2.2c-0.6,0.4-1.4,0.4-2.4,0C6.6,11.8,2.2,7.5,1,4.7C0.6,3.7,0.5,2.9,1,2.2z"/>
		<linearGradient id="CallSvg_2" gradientUnits="userSpaceOnUse" x1="8.6551" y1="2.9568" x2="13.9012" y2="2.9568">
			<stop  offset="0" style="stop-color:#CCCCCC"/>
			<stop  offset="1" style="stop-color:#0078C1"/>
		</linearGradient>
		<path style="fill:none;stroke:url(#CallSvg_2);stroke-miterlimit:10;" d="M8.7,0.8c2.5,0.3,4.4,2.2,4.7,4.7"/>
		<linearGradient id="CallSvg_3" gradientUnits="userSpaceOnUse" x1="8.6146" y1="4.1638" x2="11.5331" y2="4.1638">
			<stop  offset="5.327789e-09" style="stop-color:#CCCCCC"/>
			<stop  offset="1" style="stop-color:#0078C1"/>
		</linearGradient>
		<path style="fill:none;stroke:url(#CallSvg_3);stroke-miterlimit:10;" d="M8.7,3.2c1.2,0.2,2.1,1.2,2.3,2.3"/>
	</svg>
</template>

<script>
    export default {
        name: "CallSvg"
    }
</script>

<style scoped lang="scss">
	#CallSvg{
		.st0{fill:none;stroke:url(#CallSvg_1);stroke-miterlimit:10;}
		.st1{fill:none;stroke:url(#SVGID_00000004540628626119756670000014212522001090593435_1);stroke-miterlimit:10;}
		.st2{fill:none;stroke:url(#SVGID_00000137818488805995773590000000911061521486007730_1);stroke-miterlimit:10;}
	}
</style>