<template>
    <svg id="EducationSvg" width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.9177 43.9909L54.8428 36.9159C62.9578 19.8871 50.4847 0 31.5002 0C12.5486 0 0.026234 19.8529 8.15754 36.9159L1.08259 43.9909C-0.886405 45.9599 -0.0491931 49.3202 2.62068 50.1333L10.4794 52.5203L12.8664 60.3792C13.6762 63.0385 17.0296 63.8929 19.0088 61.9173L29.3371 51.5889C30.757 51.7066 32.1949 51.7106 33.6633 51.5889L43.9916 61.9173C45.9637 63.8894 49.3225 63.0439 50.134 60.3792L52.521 52.5203L60.3798 50.1333C63.0538 49.319 63.8834 45.9566 61.9177 43.9909ZM16.3991 59.3063L14.0109 51.4475C13.8354 50.8668 13.5191 50.3384 13.0903 49.9093C12.6614 49.4802 12.1332 49.1637 11.5525 48.9879L3.69377 46.6009L10.0552 40.2394C13.5102 45.3679 18.7543 49.1958 24.8856 50.8201L16.3991 59.3063ZM12.7225 37.5717C3.44891 22.8037 14.2382 3.69132 31.5002 3.69132C48.9016 3.69132 59.4681 22.9363 50.2779 37.5716C41.5763 51.4491 21.4227 51.4472 12.7225 37.5717ZM51.448 48.9878C50.8673 49.1637 50.3391 49.4803 49.91 49.9093C49.481 50.3384 49.1644 50.8667 48.9884 51.4474L46.6015 59.3062L38.1152 50.8198C44.2465 49.1956 49.4905 45.3677 52.9456 40.2392L59.307 46.6006L51.448 48.9878Z" fill="url(#EducationSvg_1)"/>
        <path d="M31.5002 7.38276C19.1487 7.38276 10.2192 19.3721 13.8459 31.2276C16.9358 41.3527 27.8967 46.7325 37.7016 43.2244C44.8429 40.6686 49.9567 33.8365 49.9567 25.8395C49.9567 15.6624 41.6771 7.38276 31.5002 7.38276ZM21.546 36.7338C11.5795 27.6368 18.136 11.0741 31.5002 11.0741C40.5954 11.0741 47.5189 19.2581 46.0821 28.1551C44.2744 39.6874 30.2377 44.6875 21.546 36.7338Z" fill="url(#EducationSvg_2)"/>
        <path d="M37.0999 20.8479L28.9645 26.8866L26.1463 23.8838C25.4486 23.1403 24.2807 23.1033 23.5374 23.8009C22.7942 24.4985 22.7571 25.6666 23.4547 26.4098L27.3983 30.6118C28.0401 31.2958 29.0921 31.3889 29.8441 30.8308L39.3 23.812C40.1185 23.2045 40.2894 22.0484 39.6819 21.2299C39.0744 20.4115 37.9184 20.2404 37.0999 20.8479Z" fill="url(#EducationSvg_3)"/>
        <defs>
            <linearGradient id="EducationSvg_1" x1="6.78462" y1="10.5" x2="56.0277" y2="51.4785" gradientUnits="userSpaceOnUse">
                <stop stop-color="#cccccc"/>
                <stop offset="1" stop-color="#0794b8"/>
            </linearGradient>
            <linearGradient id="EducationSvg_2" x1="6.78462" y1="10.5" x2="56.0277" y2="51.4785" gradientUnits="userSpaceOnUse">
                <stop stop-color="#cccccc"/>
                <stop offset="1" stop-color="#0794b8"/>
            </linearGradient>
            <linearGradient id="EducationSvg_3" x1="6.78462" y1="10.5" x2="56.0277" y2="51.4785" gradientUnits="userSpaceOnUse">
                <stop stop-color="#cccccc"/>
                <stop offset="1" stop-color="#0794b8"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "EducationSvg"
    }
</script>

<style scoped>

</style>