<template>
    <svg version="1.1" id="ViewSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
        <linearGradient id="ViewSvg_1" gradientUnits="userSpaceOnUse" x1="2" y1="19" x2="29" y2="19">
	        <stop  offset="0" style="stop-color:#CCE0F4"/>
            <stop  offset="0.0641" style="stop-color:#C3DCF2"/>
            <stop  offset="0.1706" style="stop-color:#A9D2EE"/>
            <stop  offset="0.3066" style="stop-color:#80C2E7"/>
            <stop  offset="0.4649" style="stop-color:#47ACDE"/>
            <stop  offset="0.5674" style="stop-color:#1E9CD7"/>
            <stop  offset="0.8652" style="stop-color:#0075BE"/>
            <stop  offset="0.9944" style="stop-color:#005B97"/>
        </linearGradient>
        <path class="st0" d="M28.87,19.46C20.96,30.37,11.39,30.69,2.63,21.2c-0.79-0.86-0.84-2.33-0.11-3.26c9.33-11.87,18.3-9.41,26.33,0.65C29.04,18.83,29.05,19.21,28.87,19.46z"/>
        <path class="st1" d="M28.17,18.75C20.53,29.04,10.99,28.58,3,19.94c-0.44-0.48-0.42-1.29,0.05-1.74c9.85-9.29,16.29-7.99,25.08,0.1C28.25,18.42,28.27,18.62,28.17,18.75z"/>
        <linearGradient id="ViewSvg_2" gradientUnits="userSpaceOnUse" x1="11.1018" y1="18.8813" x2="19.4045" y2="18.8813">
	        <stop  offset="0" style="stop-color:#CCE0F4"/>
            <stop  offset="0.0641" style="stop-color:#C3DCF2"/>
            <stop  offset="0.1706" style="stop-color:#A9D2EE"/>
            <stop  offset="0.3066" style="stop-color:#80C2E7"/>
            <stop  offset="0.4649" style="stop-color:#47ACDE"/>
            <stop  offset="0.5674" style="stop-color:#1E9CD7"/>
            <stop  offset="0.8652" style="stop-color:#0075BE"/>
            <stop  offset="0.9944" style="stop-color:#005B97"/>
        </linearGradient>
        <ellipse style="fill:none;stroke:url(#ViewSvg_2);stroke-miterlimit:10;" cx="15.25" cy="18.88" rx="3.65" ry="3.98"/>
    </svg>
</template>

<script>
    export default {
        name: "ViewSvg"
    }
</script>

<style scoped lang="scss">
    .st0{fill:url(#ViewSvg_1);}
    .st1{fill:#000000;}
    .st2{fill:none;stroke:url(#SVGID_00000153671632295825175780000008206469937020086195_);stroke-miterlimit:10;}
</style>