<template>
    <svg id="AttestationSvg" width="65" height="63" viewBox="0 0 65 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.4826 25.6053C56.104 22.007 61.8684 16.693 58.8594 12.2369C56.7983 9.18443 51.6511 10.2205 48.9398 8.7054C46.7354 6.58873 46.0851 1.31935 42.5691 0.294442C39.1634 -0.986697 35.6143 2.94584 32.5503 3.25777C29.4862 2.95698 25.9371 -0.997837 22.4983 0.23874C18.9824 1.26365 18.3651 6.52189 16.1277 8.6497C13.4494 10.1759 8.30217 9.12873 6.20802 12.1812C4.00365 15.0888 6.56072 19.7232 5.93247 22.7645C4.60985 25.5607 -0.129548 27.7777 0.00271422 31.4985C-0.118526 35.2194 4.59883 37.4363 5.93247 40.2326C6.56072 43.2739 3.98161 47.9082 6.20802 50.8159C8.26911 53.8683 13.4163 52.8323 16.1277 54.3474C20.1286 59.6947 20.1727 66.2341 28.913 61.1875C31.7787 59.3717 33.3218 59.3828 36.1875 61.1875C41.2575 64.4182 44.7845 63.1259 46.9889 57.5446C48.7855 51.1501 55.4316 55.1717 58.8594 50.8604C61.0638 47.9528 58.5067 43.3184 59.135 40.2771C59.7149 39.1764 60.5143 38.2091 61.4826 37.4363C66.1559 33.4815 66.189 29.5267 61.4826 25.6053ZM59.2121 34.9632C50.4498 41.7476 62.188 49.5125 52.2022 50.0918C43.7705 50.6711 45.8426 55.7622 41.5772 59.5833C38.998 59.7838 35.46 56.1632 32.5503 56.3972C29.6405 56.1632 26.0914 59.7393 23.5234 59.5833C21.2529 58.2799 20.5364 53.1888 18.0675 51.6514C15.8632 49.7464 10.8482 50.6042 8.9194 48.8552C7.81721 43.285 12.226 40.266 5.88839 34.9632C-2.02531 28.3792 12.2039 29.0922 8.98553 18.3084C8.69896 16.7042 8.37933 14.8994 8.9194 14.1419C13.8462 11.2788 17.9904 14.7435 21.0434 6.88952C21.8701 5.26303 22.5204 3.43602 23.832 3.35803C29.4531 4.9511 31.4481 8.85022 37.7636 4.76172C39.1524 4.00417 40.7285 3.14637 41.5772 3.42488C43.8477 4.7283 44.5641 9.81943 47.0881 11.3568C49.2925 13.2618 54.3074 12.404 56.2362 14.153C56.7763 14.9106 56.4567 16.7153 56.1701 18.3195C52.8966 29.1034 67.1258 28.3792 59.2121 34.9632Z" fill="url(#AttestationSvg_1)"/>
        <path d="M45.6993 23.2993H43.0871C44.0989 21.5297 44.6453 19.5276 44.6743 17.484C44.9278 13.3064 38.8988 11.0895 36.8488 15.0331C34.8057 18.8889 31.8049 22.1408 28.1415 24.469C28.0366 24.1318 27.8286 23.8368 27.5473 23.6266C27.2661 23.4165 26.9262 23.3018 26.5764 23.2993H18.2218C17.7834 23.2993 17.3628 23.4753 17.0528 23.7887C16.7427 24.1021 16.5686 24.5271 16.5686 24.9703V43.9089C16.5686 44.3521 16.7427 44.7771 17.0528 45.0905C17.3628 45.4039 17.7834 45.5799 18.2218 45.5799H22.9612C23.9667 45.6211 24.9622 45.3648 25.8256 44.8423C26.6891 44.3199 27.3828 43.5541 27.8219 42.6389C32.8647 43.9384 38.0483 44.597 43.2525 44.5996C44.4114 44.6062 45.5375 44.2104 46.4424 43.4783C47.3472 42.7463 47.9759 41.7224 48.2233 40.5779L50.6371 29.6158C50.8025 28.8633 50.8 28.083 50.6298 27.3316C50.4596 26.5802 50.126 25.8765 49.6532 25.2716C49.1804 24.6668 48.5802 24.176 47.8963 23.8348C47.2124 23.4936 46.4619 23.3107 45.6993 23.2993ZM22.9612 42.2378H19.82V26.6414H24.9452V40.5334C24.9364 40.7808 24.8767 41.0237 24.7699 41.2465C24.6631 41.4694 24.5116 41.6673 24.3249 41.8276C24.1383 41.988 23.9206 42.1073 23.6858 42.1779C23.451 42.2485 23.2043 42.2689 22.9612 42.2378ZM47.4408 28.8694L45.027 39.8315C44.9389 40.2313 44.7181 40.5886 44.4013 40.8439C44.0845 41.0993 43.6908 41.2374 43.2855 41.2352C38.2128 41.2425 33.1602 40.5911 28.2517 39.2968V28.3013C33.1185 25.628 37.1007 21.5681 39.7034 16.6262C39.968 15.8798 41.4118 16.5594 41.3236 17.3726C41.2575 19.4893 40.5631 22.0961 38.7886 23.0987C38.4232 23.2624 38.1191 23.5399 37.9208 23.8906C37.7226 24.2414 37.6404 24.647 37.6864 25.0483C37.7036 25.4788 37.8857 25.8857 38.1942 26.1829C38.5027 26.4802 38.9135 26.6446 39.3397 26.6414H45.7324C45.9953 26.6505 46.2531 26.7175 46.4878 26.8378C46.7224 26.958 46.9283 27.1286 47.091 27.3376C47.2537 27.5466 47.3692 27.7889 47.4297 28.0477C47.4901 28.3065 47.4939 28.5756 47.4408 28.836V28.8694Z" fill="url(#EducationSvg_3)"/>
        <defs>
            <linearGradient id="AttestationSvg_1" x1="7" y1="10.5" x2="56.5" y2="53" gradientUnits="userSpaceOnUse">
                <stop stop-color="#cccccc"/>
                <stop offset="1" stop-color="#0794b8"/>
            </linearGradient>
            <linearGradient id="EducationSvg_3" x1="7" y1="10.5" x2="56.5" y2="53" gradientUnits="userSpaceOnUse">
                <stop stop-color="#cccccc"/>
                <stop offset="1" stop-color="#0794b8"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "AttestationSvg"
    }
</script>

<style scoped>

</style>