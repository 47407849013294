<template>
    <svg version="1.1" id="DownloadSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
        <linearGradient id="DownloadSvg_1" gradientUnits="userSpaceOnUse" x1="12.25" y1="9.75" x2="19.75" y2="9.75">
	        <stop  offset="0" style="stop-color:#CCCCCC"/>
            <stop  offset="1" style="stop-color:#0078C1"/>
        </linearGradient>
        <rect x="13" y="6" class="st0" width="6" height="7.5"/>
        <linearGradient id="DownloadSvg_2" gradientUnits="userSpaceOnUse" x1="7.212" y1="18.7509" x2="24.788" y2="18.7509">
	        <stop  offset="0" style="stop-color:#CCCCCC"/>
            <stop  offset="1" style="stop-color:#0078C1"/>
        </linearGradient>
        <polygon style="fill:none;stroke:url(#DownloadSvg_2);stroke-width:1.5;stroke-miterlimit:10;" points="16,23.37 23.29,13.63 8.71,13.63 "/>
        <linearGradient id="DownloadSvg_3" gradientUnits="userSpaceOnUse" x1="6.5" y1="25.75" x2="25.5" y2="25.75">
	        <stop  offset="0" style="stop-color:#CCCCCC"/>
            <stop  offset="1" style="stop-color:#0078C1"/>
        </linearGradient>
        <rect x="7" y="25.5" style="fill:none;stroke:url(#DownloadSvg_3);stroke-miterlimit:10;" width="18" height="0.5"/>
    </svg>
</template>

<script>
    export default {
        name: "DownloadSvg"
    }
</script>

<style scoped lang="scss">
    .st0{fill:none;stroke:url(#DownloadSvg_1);stroke-width:1.5;stroke-miterlimit:10;}
    .st1{fill:none;stroke:url(#SVGID_00000006697363131211983690000003199875995904696745_);stroke-width:1.5;stroke-miterlimit:10;}
    .st2{fill:none;stroke:url(#SVGID_00000128444706645200481650000015735818802022463151_);stroke-miterlimit:10;}
</style>