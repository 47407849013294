<template>
    <svg version="1.1" id="MailSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 23 23" style="enable-background:new 0 0 24 24;" xml:space="preserve">
        <linearGradient id="MailSvg_1" gradientUnits="userSpaceOnUse" x1="4.9542" y1="10.1323" x2="18.2677" y2="10.1323">
	        <stop  offset="0" style="stop-color:#CCCCCC"/>
            <stop  offset="1" style="stop-color:#0078C1"/>
        </linearGradient>
        <path class="st0" d="M17.3,8.2L13,11.6c-0.8,0.6-1.9,0.6-2.7,0L6,8.2"/>
        <linearGradient id="MailSvg_2" gradientUnits="userSpaceOnUse" x1="1" y1="12.0581" x2="22.2307" y2="12.0581">
	        <stop  offset="5.327789e-09" style="stop-color:#CCCCCC"/>
            <stop  offset="1" style="stop-color:#0078C1"/>
        </linearGradient>
        <path style="fill:none;stroke:url(#MailSvg_2);stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" d="M6.9,3.5h9.4c1.4,0,2.7,0.6,3.6,1.6c0.9,1,1.4,2.3,1.3,3.7v6.5c0.1,1.4-0.4,2.7-1.3,3.7s-2.2,1.6-3.6,1.6H6.9C4,20.6,2,18.2,2,15.3V8.8C2,5.9,4,3.5,6.9,3.5z"/>
    </svg>
</template>

<script>
    export default {
        name: "MailSvg"
    }
</script>

<style scoped lang="scss">
    #MailSvg{
        .st0{fill:none;stroke:url(#MailSvg_1);stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
        .st1{fill:none;stroke:url(#SVGID_00000142146083753977866180000015480039174186702223_);stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}
    }
</style>