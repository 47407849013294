<template>
    <svg version="1.1" id="LocationSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
        <linearGradient id="LocationSvg_1" gradientUnits="userSpaceOnUse" x1="3.3914" y1="12" x2="20.6087" y2="12">
	        <stop  offset="7.458905e-08" style="stop-color:#CCCCCC"/>
            <stop  offset="1" style="stop-color:#0078C1"/>
        </linearGradient>
        <path class="st0" d="M17.8,2.1C16.4,0.7,14.3,0,12,0C9.7,0,7.6,0.7,6.2,2.1c-1.8,1.6-2.8,4-2.8,7.1c0,6.5,7.5,14.2,7.9,14.6c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,7.9-8,7.9-14.6C20.6,6.1,19.7,3.7,17.8,2.1z M12,21.4c-0.7-0.8-1.8-2.1-2.9-3.7c-2.3-3.3-3.6-6.3-3.6-8.6c0-6.5,5-7,6.5-7c6.1,0,6.5,5.4,6.5,7C18.5,13.7,13.9,19.4,12,21.4z M12,4.5c-2.3,0-4.1,1.9-4.1,4.2c0,2.3,1.9,4.2,4.1,4.2c2.3,0,4.1-1.9,4.1-4.2C16.1,6.4,14.3,4.5,12,4.5z M12,11.1c-1.3,0-2.3-1.1-2.3-2.4c0-1.3,1-2.4,2.3-2.4c1.3,0,2.3,1.1,2.3,2.4C14.3,10,13.3,11.1,12,11.1z"/>
    </svg>
</template>

<script>
    export default {
        name: "LocationSvg"
    }
</script>

<style scoped lang="scss">
    #LocationSvg{
        .st0{fill:url(#LocationSvg_1);}
    }
</style>